import "./SpinningTrophy.css";
import React from "react";

export default function SpinningTrophy() {
  return (
    <div className="trophyHeader">
      <img src="../trophy.png" className="App-logo" alt="logo" />
    </div>
  );
}
